/* eslint-disable jsx-a11y/accessible-emoji */
/* istanbul ignore file */
import Image from 'next/image'
import { Tag } from '@tofu/shared/ui/atoms/tag'
import { Box } from '@tofu/shared/ui/atoms/box'
import { Text } from '@tofu/shared/ui/atoms/text'
import { TGetProductCardTagNew } from './product-card-tag-new.types'

const typeMap = new Map([
  [
    'new',
    <Tag size='sm' color='green.600' bg='white'>
      New
    </Tag>
  ],
  [
    'spring_menu',
    <Tag size='sm' bg='white'>
      <Box as='span' role='img' aria-label='spring-menu' mr={1}>
        🌱
      </Box>{' '}
      Spring Menu
    </Tag>
  ],
  [
    'autumn_menu',
    <Tag size='sm' bg='white'>
      <Box as='span' role='img' aria-label='autumn-menu' mr={1}>
        🍂
      </Box>{' '}
      Autumn Menu
    </Tag>
  ],
  [
    'winter_menu',
    <Tag size='sm' bg='white'>
      <Box as='span' role='img' aria-label='winter-menu' mr={1}>
        ❄️
      </Box>{' '}
      Winter Menu
    </Tag>
  ],
  [
    'summer_menu',
    <Tag size='sm' bg='white'>
      <Box as='span' role='img' aria-label='summer-menu' mr={1}>
        ☀️
      </Box>{' '}
      Summer Menu
    </Tag>
  ],
  [
    'spice-medium',
    <Tag size='sm' color='#E58117' bg='white'>
      <Image src='/img/icon/spice-medium.png' width={12} height={12} alt='' />
      <Text ml={1}>Medium</Text>
    </Tag>
  ],
  [
    'spice-spicy',
    <Tag size='sm' color='#DD4425' bg='white'>
      <Image src='/img/icon/spice-spicy.png' width={12} height={12} alt='' />
      <Text ml={1}>Spicy</Text>
    </Tag>
  ],
  [
    'bestseller',
    <Tag size='sm' bg='white'>
      <Box as='span' role='img' aria-label='bestseller' mr={1}>
        ⭐️
      </Box>{' '}
      Bestseller
    </Tag>
  ],
  [
    'trending',
    <Tag size='sm' bg='white'>
      <Box as='span' role='img' aria-label='trending' mr={1}>
        🔥
      </Box>{' '}
      Trending
    </Tag>
  ]
])

export const getTypeTag: TGetProductCardTagNew = (type: string) =>
  typeMap.get(type) || null

export const getSpiceTagNameByNumber = (spice: number) => {
  switch (spice) {
    case 2:
      return 'spice-medium'
    case 3:
      return 'spice-spicy'
    default:
      return null
  }
}
